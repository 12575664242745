<template>
  <v-app>
    <v-app-bar fixed height="88" light app color="#fff" elevation="0">
      <v-row class="d-flex justify-center align-center" no-gutters>
        <v-col class="title-text text-center" xl="4" md="6" xs="12">
          Community Impact Dashboard
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({}),
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";

.m-hide {
  @include mobile {
    display: none;
  }
}

.app-bar-content {
  width: 100%;
  text-align: center;
}

.title-text {
  font-weight: bold;
  font-size: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sub-title {
  font-size: 16px;
  line-height: 2;
}
</style>
